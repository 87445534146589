@import 'constants.scss';
@import 'TrainingSidebar/index';
@import 'TemplateTrainingSidebar/index';

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* react split pane styles  */

.Resizer {
  background: #000;
  opacity: .2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
  border-top: 3px solid rgba(0, 0, 0, 0.5);
  border-bottom: 3px solid rgba(0, 0, 0, 0.5);
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 8px;
  margin: -5px 0;
  /* border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0); */
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 3px solid rgba(0, 0, 0, 0.5);
  border-bottom: 3px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.disabled {
  cursor: not-allowed;
}

.Resizer.disabled:hover {
  border-color: transparent;
}