@import '../constants';

.template-training-sidebar {

  width: 100%;
  height: 100%;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;

  .training-selection-bar {
    background-color: $very-light-gray;
    width: 100%;
    min-height: 100px;
    max-height: 100px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
  };

  .centered-button {
    margin: auto 5px !important;
  }
};